import React, { useEffect, useState, useRef } from "react";
import Avatar from "@mui/joy/Avatar";
import Button from "@mui/joy/Button";
import ButtonGroup from "@mui/joy/ButtonGroup";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import CardActions from "@mui/joy/CardActions";
import Typography from "@mui/joy/Typography";
import { base_url } from "../env";
import { useNavigate } from "react-router-dom";
import toast,{Toaster} from "react-hot-toast";
import {motion} from "framer-motion"

const ProfileData = () => {
  const [data, setData] = useState({});
  const Navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [loading,setLoading] = useState(false);

  const profileData = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("brainbucks_token")}`
      );

      var raw = "";

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${base_url}/auth/user/auth/view/profile`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 1) {
            setData(result.data);
          } else if (result.status == "TOKEN_ERR") {
            localStorage.removeItem("brainbucks_token");
            localStorage.removeItem("username");
            Navigate("/");
            // toast.error("Logged in other device! Please login again");
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };

  const updateProfile = async()=>{
    setLoading(true);
    try {
        var myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${localStorage.getItem("brainbucks_token")}`);

var formdata = new FormData();
formdata.append("profile", fileInputRef.current.files[0]);

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

fetch(`${base_url}/auth/user/auth/update/profile/photo`, requestOptions)
  .then(response => response.json())
  .then(result => {
    if(result.status==1){
        toast.success("updated successfully")
        profileData();
        fileInputRef(null);
    } 
    else if(result.status==0){
      toast.error(result.msg);
    }
    else if (result.status == 'FILE_ERR') { 
      toast.error(result.Backend_Error) 
    } 
    else if (result.status == "CUSTOM_ERR") {
      toast.error(result.Backend_Error);
    } else if (result.status == "VAL_ERR") {
      toast.error(result.Backend_Error);
    }
    else if (result.status === "TOKEN_ERR") {
      localStorage.removeItem("brainbucks_token");
      localStorage.removeItem("username");
      Navigate("/");
      toast.error("Logged in other device! Please login again");
    }
  })
  .catch(error => console.log('error', error))
  .finally(()=>{
    setLoading(false);
  })
    } catch (error) {
        console.log(error);
    }
  }

  const handleUpdateProfileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };


  const handleFileChange = () => {
    if (fileInputRef.current.files.length > 0) {
      updateProfile();
    }
  };


  const containerVariants = {
    hidden : {
      opacity : 0,
      x:-500
    },
    visible:{
      opacity:1,
      x:0,
      transition : {duration:.3}
    },
    exit:{
      y: '-100vw',
      transition : {ease:'easeInOut'}
    }
  }
  useEffect(() => {
    profileData();
  }, []);


  return (
    <>
      <motion.div variants={containerVariants} initial="hidden" animate="visible" exit="exit" className="d-flex align-items-center justify-content-center h-100">
      
        <Card
          sx={{
            width: 320,
            maxWidth: "100%",
            boxShadow: "lg",
          }}
        >
          <CardContent sx={{ alignItems: "center", textAlign: "center" }}>
            <Avatar
              src={data.profile}
              sx={{ "--Avatar-size": "4rem" }}
            />
            <Typography level="title-lg">{data.name}</Typography>
            <Typography level="body-sm" sx={{ maxWidth: "24ch" }}>
              Hello, this is{" "}
              {data.name &&
                data.name.charAt(0).toUpperCase() + data.name.slice(1)}{" "}
              and I am a user of BrainBucks.
            </Typography>
            <CardContent orientation="vertical" sx={{mt:2}} >
          <Typography level="body-xs">{data.email}</Typography>
          <Typography level="body-xs">+91 {data.mobile}</Typography>
        </CardContent>
          </CardContent>
          <CardOverflow sx={{ bgcolor: "background.level1" }}>
            <CardActions buttonFlex="1">
              <ButtonGroup
                variant="outlined"
                sx={{ bgcolor: "background.surface" }}
              >
                 <input
                  type="file"
                  hidden
                  accept=".png,.jpeg,.jpg"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                />
                
                {
                  loading==false?(
                <Button onClick={handleUpdateProfileClick}>
                  Update Image
                </Button>
                  ):(<></>)
                }
                {
                  loading==true?(
                    <Button loading loadingPosition="start">
        Updating Image
      </Button>
                  ):(<></>)
                }
              </ButtonGroup>
            </CardActions>
          </CardOverflow>
        </Card>
      </motion.div>
      <Toaster/>
    </>
  );
};

export default ProfileData;

import React, { useContext } from "react";
import { QuizContext } from "../../../App";
import VerifiedIcon from "@mui/icons-material/Verified";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Typography from "@mui/joy/Typography";
import Card from "@mui/joy/Card";
import Divider from "@mui/joy/Divider";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import CardOverflow from "@mui/joy/CardOverflow";
import Button from "@mui/joy/Button";
import { useNavigate } from "react-router-dom";

const QuizResult = () => {
  const Navigate = useNavigate();
  const { quizResult } = useContext(QuizContext);
  return (
    <>
      <div
        className="container quiz-result"
        style={{
          background: quizResult.result == "pass" ? "#185EA5" : "#f44336",
        }}
      >
        <div className="row">
          <div
            className="col-12  d-flex justify-content-center align-items-center"
            style={{ height: "30vh" }}
          >
            <div className="text-center text-white">
              {quizResult.result == "pass" ? (
                <>
                  <VerifiedIcon sx={{ fontSize: "5rem" }} />
                  <Typography level="h4" className="text-white">
                    Excellent Work
                  </Typography>
                </>
              ) : (
                <></>
              )}
              {quizResult.result == "fail" ? (
                <>
                  <ErrorOutlineIcon sx={{ fontSize: "5rem" }} />
                  <Typography level="h4" className="text-white">
                    Focus On Your Studies
                  </Typography>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="p-0 d-flex flex-column align-items-center" style={{ height: "70vh",}}>
            <Card className="h-100 w-100 quizCardBorder " style={{display:"flex",justifyContent:"center",}} >
              <Divider />
              <List className="h-100" >
              <ListItem className="d-flex justify-content-between align-items-center" >
                  <Typography>
                    Correct Answers
                  </Typography>
                  <Typography>
                    {quizResult.correct}
                  </Typography>
                </ListItem>
                <Divider component="li" light />
                <ListItem className="d-flex justify-content-between align-items-center" >
                  <Typography>
                    Wrong Answers
                  </Typography>
                  <Typography>
                    {quizResult.wrong}
                  </Typography>
                </ListItem>
                <Divider component="li" light />
                <ListItem className="d-flex justify-content-between align-items-center" >
                  <Typography>
                    Unattempted Answers
                  </Typography>
                  <Typography>
                    {quizResult.unattemped}
                  </Typography>
                </ListItem>
                <Divider component="li" light />
                <ListItem className="d-flex justify-content-between align-items-center" >
                  <Typography>
                    Scored Marks
                  </Typography>
                  <Typography>
                    {quizResult.marks_score}
                  </Typography>
                </ListItem>
                <Divider component="li" light />
                <ListItem className="d-flex justify-content-between align-items-center" >
                  <Typography>
                    Total Marks
                  </Typography>
                  <Typography>
                    {quizResult.total_marks}
                  </Typography>
                </ListItem>

                
                {/* <CardOverflow> */}
        <Button variant="outlined" className="mt-3 py-3"  style={{
          color: quizResult.result == "pass" ? "#185EA5" : "#f44336",
          border: `1px solid ${quizResult.result === "pass" ? "#185EA5" : "#f44336"}`

        }} size="lg">
          {quizResult.result}
        </Button>
      {/* </CardOverflow> */}

      <Button variant="solid" className="mt-3 py-3" onClick={()=>Navigate("/Home")} >
        Back to Home
      </Button>
                

              </List>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuizResult;

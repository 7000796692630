import React, { useState,useEffect } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Button from "@mui/joy/Button";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import {base_url , client_id} from ".././env"

const Login = () => {

  const Navigate = useNavigate();
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading,setIsLoading] = useState(false);
  

  useEffect(() => {
    // Check if the user is already logged in and redirect if necessary
    const isLoggedIn = localStorage.getItem("brainbucks_token") !== null;
    if (isLoggedIn) {
      Navigate("/Home");
    }
  }, [Navigate]);

    // login api
    const loginApi = () => {
      setIsLoading(true);
      try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({ 
          mobile,
          password ,
          client_id

        });

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        fetch(`${base_url}/auth/user/auth/login`, requestOptions) 
          .then((response) => response.json())
          .then((result) => {
            if (result.status == 1) {

              localStorage.setItem("brainbucks_token", result.token);
              localStorage.setItem("username", result.userName);

              Navigate("/Home");
              // toast.success("Logged in")
              setTimeout(()=>{
              } , 1000)
              
            } else if (result.status == "CUSTOM_ERR") {
              toast.error(result.Backend_Error);
            } else if (result.status == "VAL_ERR") {
              toast.error(result.Backend_Error);
            } else {
              console.log(result);
            }
          })
          .catch((error) => console.log("error", error))
          .finally(()=>{setIsLoading(false)})
      } catch (e) {
        console.log(e);
      }
    };
  



  return (
    <>
      
      <div
        className="container-fluid m-0 w-100 position-relative"
        style={{ height: "100vh", background: "#F6F6F6", overflowY: "hidden" }}
      >
        <div className="row flex-column justify-content-center h-100">
          {/* <div className="col-12"></div> */}
          <div className="col-12 px-4 d-flex flex-column gap-3">
            <div>
              <h1 style={{ fontWeight: 700 }}>Login</h1>
              <p>BrainBucks welcome's you back 👋</p>
            </div>

            <div className="mt-4">
              <TextField
                value={mobile}
                id="outlined-email"
                onChange={(e) => setMobile(e.target.value)}
                label="Mobile"
                variant="outlined"
                type={"tel"}
                fullWidth={true}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+91</InputAdornment>
                  ),
                  style: { borderRadius: "10px" },
                }}
                inputProps={{ pattern: "[0-9]*", maxLength: 10 }}
              />

              <TextField
                value={password}
                id="outlined-password"
                onChange={(e) => setPassword(e.target.value)}
                label="Password"
                className="mt-4"
                variant="outlined"
                type={showPassword ? "text" : "password"}
                fullWidth={true}
                InputProps={{
                  style: { borderRadius: "10px" },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={()=>{setShowPassword(!showPassword)}} edge="end">
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <h6 onClick={()=>{
                Navigate("/ForgetPassword")
              }} className="text-end mt-3" style={{ color: "#10133f",cursor:"pointer" }}>
                Forget Password?
              </h6>
            </div>

            {
              isLoading==false?(
                <>
                <Button
              onClick={loginApi}
              className="py-3 mt-5"
              style={{ background: "#10133f" }}
              sx={{ py: 1 ,color:"white"}}
              variant="contained"
              fullWidth={true}
            >
              Login 
            </Button>
                </>
              ):(<></>)
            }

            {
              isLoading==true?(
                <>
                <Button
                 loading loadingPosition="start"
              className="py-3 mt-5"
              style={{ background: "#10133f" }}
              sx={{ py: 1 ,color:"white"}}
              variant="contained"
              fullWidth={true}
            >
              Processing 
            </Button>
                </>
              ):(<></>)
            }

            

                <div style={{textAlign:"center" ,fontSize:"smaller"}}  >
                    <span >New User ?</span> <span style={{color:"#0000EE" , cursor:"pointer"}} onClick={()=>{Navigate("/Register")}}> SignUp</span>
                </div>

          </div>
          {/* <div className="col-12"></div> */}
          {/* <div></div> */}
        </div>
      </div>
      <Toaster position="top-center" />
    </>
  );
};

export default Login;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { base_url, token } from "../env";
import { toast, Toaster } from "react-hot-toast";
import Skeleton from "@mui/material/Skeleton";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import Divider from "@mui/joy/Divider";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import TimerIcon from '@mui/icons-material/Timer';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
// import cordova from "cordova"

const StudyMaterial = () => {
  const { id } = useParams();
  const Navigate = useNavigate();
  const [data, setData] = useState([]);
  const [skeleton,setSkeleton] = useState(false)

  const getData = async () => {
    setSkeleton(true)
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("brainbucks_token")}`
      );

      var raw = JSON.stringify({
        prereccou_id: parseInt(id),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${base_url}/prerec/studymaterails/get/study/materials/of/particular/course/byuser`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 1) {
            setData(result.study_materials);
          } else if (result.status === "TOKEN_ERR") {
            localStorage.removeItem("brainbucks_token");
            localStorage.removeItem("username");
            Navigate("/");
            toast.error("Logged in other device! Please login again");
          }
        })
        .catch((error) => console.log("error", error))
        .finally(()=>{
            setSkeleton(false);
        })
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
                  <div
        className="container-fluid w-100"
        style={{ height: "100vh", overflowY: "auto" }}
      >
        <div className="row h-100">
          <div className="col-12 p-0">
            <div>
            <Box sx={{ flexGrow: 1 }} >
        <AppBar
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            paddingTop: "0.7rem",
            paddingBottom: "0.7rem",
          }}
          className="bg-white px-3 d-flex flex-row align-items-center justify-content-between"
          elevation={0}
        >
          <TrendingFlatIcon sx={{ color: "black",transform:"rotate(180deg)" }} onClick={()=>{Navigate(-1)}} />
          <Typography level="title-lg">Study Material</Typography>
          <div></div>
        </AppBar>
      </Box>
            </div>

            <div className="py-5 mb-3 px-3 h-100" >
            <div className="row" style={{ rowGap: "10px" }}>
        
        {data.length > 0 ? (
          data?.slice().reverse().map((data) => (
            <div className="col-12" key={data._id}>
              <Card variant="outlined" className="h-100"   onClick={() => {
    const pdfUrl = `${base_url}/auth/azure/stream/${data.pdf_url}/${localStorage.getItem("brainbucks_token")}/${data.prereccou_id}/P/usdhwer34fsdfdsk`;

    if (window.cordova) {
      // Cordova-specific code
      // Open the PDF in the same window
      window.location.href = pdfUrl;
    } else {
      // Web-specific code
      // Open the PDF in a new tab for web
      window.open(pdfUrl, "_blank");
    }

    // var ref = cordova.InAppBrowser.open('https://apache.org', '_blank', 'location=yes');
  }}  >
                <CardContent className="quiz-card">
                  {skeleton == true ? (
                    <>
                      <Skeleton variant="text" sx={{ fontSize: "1rem",width:"100%" }} />
                    </>
                  ) : (
                    <>
                      <Typography level="title-md"  sx={{wordWrap:"break-word"}}  >
                        {data.title}
                      </Typography>
                    </>
                  )}
                </CardContent>
              </Card>
            </div>
          ))
        ) : (
          <h5 className="text-center d-flex h-100 mt-5 justify-content-center align-items-center">
            No data found !
          </h5>
        )}
      </div>
            </div>
          </div>
        </div>
      </div>

      <Toaster />
      
    </>
  );
};

export default StudyMaterial;

import React, { useState, useEffect } from "react";
import BottomMenu from "../bottom-navigation/BottomMenu";
import MyCoursesNavbar from "../my-courses/MyCoursesNavbar";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import Typography from "@mui/joy/Typography";
import AspectRatio from "@mui/joy/AspectRatio";

import Skeleton from "@mui/material/Skeleton";
import { base_url, token } from "../env";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

const SeeAllPaidCou = () => {
  const [courses, setCourses] = useState([]);
  const Navigate = useNavigate();
  const [skeleton, setSkeleton] = useState(false);

  const getPaidCourses = async () => {
    try {
      setSkeleton(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("brainbucks_token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      fetch(
        `${base_url}/prerec/prerecordedcourse/view/paid/pre/rec/course/by/user`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setSkeleton(false);
          if (result.status == 1) {
            setCourses(result.data);
          } else if (result.status === "VAL_ERR") {
            toast.error(result.Backend_Error);
          } else if (result.status == "CUSTOM_ERR") {
            toast.error(result.Backend_Error);
          } else if (result.status == "TOKEN_ERR") {
            localStorage.removeItem("brainbucks_token");
            localStorage.removeItem("username");
            Navigate("/");
            toast.error("Logged in other device! Please login again");
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPaidCourses();
  }, []);

  return (
    <>
      <div
        className="container-fluid w-100"
        style={{ height: "100vh", overflowY: "auto" }}
      >
        <div className="row">
          <div className="col-12 p-0">
            <div>
              <MyCoursesNavbar type={"paid"} />
            </div>

            {/* { courses.length === 0 ? <></> :  } */}
            <div className="py-5 mb-3 px-3 ">
              {skeleton === true ? (
                <>
                  <Skeleton variant="rectangular" height={150} />
                  <Skeleton
                    className="mt-1"
                    variant="rectangular"
                    height={45}
                    sx={{ marginBottom: 2 }}
                  />

                  <Skeleton variant="rectangular" height={150} />
                  <Skeleton
                    className="mt-1"
                    variant="rectangular"
                    height={45}
                    sx={{ marginBottom: 2 }}
                  />

                  <Skeleton variant="rectangular" height={150} />
                  <Skeleton
                    className="mt-1"
                    variant="rectangular"
                    height={45}
                    sx={{ marginBottom: 2 }}
                  />

                  <Skeleton variant="rectangular" height={150} />
                  <Skeleton
                    className="mt-1"
                    variant="rectangular"
                    height={45}
                    sx={{ marginBottom: 2 }}
                  />

                  <Skeleton variant="rectangular" height={150} />
                  <Skeleton
                    className="mt-1"
                    variant="rectangular"
                    height={45}
                    sx={{ marginBottom: 2 }}
                  />
                </>
              ) : (
                <>
                  {courses?.slice().reverse().map((course, index) => (
                    <Card
                    onClick={()=>{Navigate(`/ViewCourse/${course.id}`)}}
                      key={index}
                      elevation={8}
                      sx={{ marginBottom: 2 }}
                    >
                    <CardOverflow>
                    <AspectRatio ratio="3">
                    <img
            src={`${course.banner}`}
            loading="lazy"
            alt=""
          />
                    </AspectRatio>
                    </CardOverflow>

                    <CardContent className="flex-row justify-content-between align-items-center" >
        <Typography level="title-lg"   sx={{wordBreak:"break-word"}}>  {course.cou_name}</Typography>
        <Typography level="body-md" sx={{whiteSpace:"nowrap"}}> ₹ {course.total_amount}</Typography>
      </CardContent>
                    </Card>
                  ))}
                </>
              )}
            </div>

            <div>
              <BottomMenu />
            </div>
          </div>
        </div>
      </div>

      <Toaster/>
    </>
  );
};

export default SeeAllPaidCou;

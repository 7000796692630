import React ,{useState} from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Button } from "@mui/material";


import toast, { Toaster } from "react-hot-toast";
import {base_url , client_id} from ".././env"


const Register = () => {

  const Navigate = useNavigate();

  const [mobile, setMobile] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
 

  
  // register api
  const registerApi = () => {
      try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          mobile,
          name,
          email,
          password,
          client_id,
        });

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        fetch(`${base_url}/auth/user/auth/registor`, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            toast.success("Registered Successfully")
            if (result.status == 1) {

              toast.success("Registered Successfully")
              Navigate("/");

              
              setTimeout(()=>{
                Navigate("/");
              } , 1000)


            } else if (result.status == "CUSTOM_ERR") {
              toast.error(result.Backend_Error);
            } else if (result.status == "VAL_ERR") {
              toast.error(result.Backend_Error);
            } else {
              console.log(result);
            }
          })
          .catch((error) => console.log("error", error));
      } catch (e) {
        console.log(e);
      }
    };
  

  return(
    <>
    
    
    <div
        className="container-fluid m-0 w-100"
        style={{ height: "100vh", background: "#F6F6F6", overflowY: "hidden", }}
      >
        <div className="row flex-column justify-content-center h-100">
          {/* <div className="col-12"></div> */}
          <div className="col-12 px-4 d-flex flex-column gap-3">
            <div style={{textAlign:"center"}}>
              <h1 style={{ fontWeight: 700 }}>Create Account</h1>
            </div>

            <div className="mt-4 d-flex flex-column gap-3 ">


            <TextField
                value={mobile}
                id="outlined-mobile"
                onChange={(e) => setMobile(e.target.value)}
                label="Mobile"
                variant="outlined"
                type={"tel"}
                fullWidth={true}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+91</InputAdornment>
                  ),
                  style: { borderRadius: "10px" },
                }}
                inputProps={{ pattern: "[0-9]*", maxLength: 10 }}
              />

              <TextField
              value={name}
                id="outlined-name"
                onChange={(e) => setName(e.target.value)}
                label="Name"
                variant="outlined"
                type={"text"}
                fullWidth={true}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}

                
              />

              <TextField
              value={email}
                id="outlined-email"
                onChange={(e) => setEmail(e.target.value)}
                label="Email"
                variant="outlined"
                type={"email"}
                fullWidth={true}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              />

              <TextField
                value={password}
                id="outlined-password"
                onChange={(e) => setPassword(e.target.value)}
                label="Password"
                
                variant="outlined"
                type={showPassword ? "text" : "password"}
                fullWidth={true}
                InputProps={{
                  style: { borderRadius: "10px" },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={()=>{setShowPassword(!showPassword)}} edge="end">
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

            </div>

            <Button
              onClick={registerApi}
              className="py-3 mt-5"
              style={{ background: "#10133f" }}
              sx={{ py: 1 }}
              variant="contained"
              fullWidth={true}
            >
              Continue 
            </Button>
            <div style={{textAlign:"center" ,fontSize:"smaller"}}  >
                    <span >Already An User ?</span> <span style={{color:"#0000EE" , cursor:"pointer"}} onClick={()=>{Navigate("/")}}> Login</span>
                </div>


          </div>
          {/* <div className="col-12"></div> */}
          <div></div>
        </div>
      </div>

      <Toaster position="top-center" />
    

    </>
  )
  
  
 
};

export default Register;

import React from 'react'
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/joy/Typography';

const MyCoursesNavbar = ({type}) => {
    const Navigate = useNavigate();
    let heading ;
    if (type == "free") {
       heading = "Free Courses"
    } 
     else if (type == "paid") {
       heading = "Paid Courses"
    }
    else{
       heading = "My Courses"
    }

  return (
   <>
    <Box sx={{ flexGrow: 1 }} >
        <AppBar
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            paddingTop: "0.7rem",
            paddingBottom: "0.7rem",
          }}
          className="bg-white px-3 d-flex flex-row align-items-center justify-content-between"
          elevation={0}
        >
          <TrendingFlatIcon sx={{ color: "black",transform:"rotate(180deg)" }} onClick={()=>{Navigate(-1)}} />
          <Typography level="title-lg">{heading}</Typography>
          <div></div>
        </AppBar>
      </Box>
   </>
  )
}

export default MyCoursesNavbar

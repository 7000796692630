import React, { createContext, useState } from "react";
import "./App.css";
import Home from "./components/home/Home";
import Login from "./components/login/Login";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import MyCourses from "./components/my-courses/MyCourses";
import Register from "./components/register/Register";
import ViewCourse from "./components/my-courses/ViewCourse";
import Test from "./components/test/Test";
import Pdf from "./components/pdf/Pdf";
import Quiz from "./components/test/quiz/Quiz";
import SeeAllFreeCou from "./components/home/SeeAllFreeCou";
import SeeAllPaidCou from "./components/home/SeeAllPaidCou";
import QuizDetailed from "./components/test/quiz/QuizDetailed";
import AttemptQuiz from "./components/test/quiz/AttemptQuiz";
import StudyMaterial from "./components/pdf/StudyMaterial";
import ForgetPassword from "./components/login/ForgetPassword";
import VerifyOTP from "./components/login/VerifyOTP";
import Profile from "./components/profile/Profile";
import NewPassword from "./components/login/NewPassword";
import QuizResult from "./components/test/quiz/QuizResult";
import QuizHistory from "./components/test/quiz/QuizHistory";
import { AnimatePresence } from "framer-motion";
import Authentication from "./Authentication";

export const QuizContext = createContext();

function App() {
  const location = useLocation(); // Move useLocation inside the component
  const [quizResult, setQuizResult] = useState([]);

  return (
    <>
      {/* <BrowserRouter> */}
      <AnimatePresence>
        <QuizContext.Provider value={{ quizResult, setQuizResult }}>
          <Routes location={location}>
            <Route path="/" element={<Login />} />
            <Route path="/ForgetPassword" element={<ForgetPassword />} />
            <Route path="/Register" element={<Register />} />
              <Route
                path="/ForgetPassword/VerifyOTP/:email"
                element={<VerifyOTP />}
              />
              <Route
                path="/ForgetPassword/:email/:userOtp"
                element={<NewPassword />}
              />
            <Route path="/" element={<Authentication />}>
              <Route path="/Home" element={<Home />} />
              <Route path="/MyCourses" element={<MyCourses />} />
              <Route path="/SeeAllFreeCourses" element={<SeeAllFreeCou />} />
              <Route path="/SeeAllPaidCourses" element={<SeeAllPaidCou />} />
              <Route path="/ViewCourse/:id" element={<ViewCourse />} />
              <Route path="/Test" element={<Test />} />
              <Route path="/Pdf" element={<Pdf />} />
              <Route path="/Quiz/:id" element={<Quiz />} />
              <Route path="/QuizDetails/:id" element={<QuizDetailed />} />
              <Route path="/AttemptQuiz/:id" element={<AttemptQuiz />} />
              <Route path="/QuizResult" element={<QuizResult />} />
              <Route path="/StudyMaterial/:id" element={<StudyMaterial />} />
              <Route path="/Profile" element={<Profile />} />
              <Route path="/QuizHistory/:id" element={<QuizHistory />} />
            </Route>
          </Routes>
        </QuizContext.Provider>
        {/* </BrowserRouter> */}
      </AnimatePresence>
    </>
  );
}

export default App;

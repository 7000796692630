import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import {base_url , client_id} from ".././env"

const NewPassword = () => {
    
  const Navigate = useNavigate();
  const {email,userOtp} = useParams();
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [password1, setPassword1] = useState("");
  const [showPassword1, setShowPassword1] = useState(false);

  const updatePassword = async()=>{
    try{
        var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({
  "pass": password,
  "cpass": password1,
  "email": email,
  "comp_otp": userOtp
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(`${base_url}/auth/user/auth/set/passwprd/after/otp/verify`, requestOptions)
  .then(response => response.json())
  .then(result => {
    if(result.status==1){
        toast.success("password update successfully");
        setPassword("");
        setPassword1("");
        Navigate("/");
    } else if (result.status == "CUSTOM_ERR") {
        toast.error(result.Backend_Error);
      } else if (result.status == "VAL_ERR") {
        toast.error(result.Backend_Error);
      }
  })
  .catch(error => console.log('error', error));
    }catch(error){
        console.log(error);
    }
  }


  return (
    <>
      <div
        className="container-fluid m-0 w-100 position-relative"
        style={{ height: "100vh", background: "#F6F6F6", overflowY: "hidden" }}
      >
        
        <div className="row flex-column justify-content-center h-100">
          <div className="col-12"></div>
          <div className="col-12 px-4 d-flex flex-column gap-3">

            <div className="mt-4">

              <TextField
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                label="Password"
                className="mt-4"
                variant="outlined"
                type={showPassword ? "text" : "password"}
                fullWidth={true}
                InputProps={{
                  style: { borderRadius: "10px" },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={()=>{setShowPassword(!showPassword)}} edge="end">
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />


              <TextField
                value={password1}
                onChange={(e) => setPassword1(e.target.value)}
                label="Confirm Password"
                className="mt-4"
                variant="outlined"
                type={showPassword1 ? "text" : "password"}
                fullWidth={true}
                InputProps={{
                  style: { borderRadius: "10px" },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={()=>{setShowPassword1(!showPassword1)}} edge="end">
                        {showPassword1 ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <Button
              onClick={()=>{
                if(password<=0){
                    toast.error("pls enter password");
                }else if(password1<=0){
                    toast.error("pls enter confirm password");
                }else if(password!==password1){
                    toast.error("password not match");
                }else{
                    updatePassword();
                }
              }}
              className="py-3 mt-5"
              style={{ background: "#10133f" }}
              sx={{ py: 1 }}
              variant="contained"
              fullWidth={true}
            >

              Change Password 
            </Button>


          </div>
          <div className="col-12"></div>
          <div></div>
        </div>
      </div>
      <Toaster position="top-center" />
    </>
  )
}

export default NewPassword

import React, { useState,useEffect } from "react";
import Typography from "@mui/joy/Typography";
import { Button } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import { base_url } from ".././env";
import { useNavigate, useParams } from "react-router-dom";
import { MuiOtpInput } from "mui-one-time-password-input";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";

const VerifyOTP = () => {
  const { email } = useParams();
  const Navigate = useNavigate();
  const [otp, setOtp] = React.useState("");
  const [resendDisabled, setResendDisabled] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const [intervalId, setIntervalId] = useState(null);

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const validateChar = (value, index) => {
    return isNumeric(value);
  };

  const isNumeric = (value) => {
    return /^[0-9]+$/.test(value);
  };

  const startResendTimer = () => {
    setResendDisabled(true);
    setCountdown(60);

    const id = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 1) {
          setResendDisabled(false);
          clearInterval(id);
        }
        return prevCountdown - 1;
      });
    }, 1000);

    setIntervalId(id);
  };

  const verifyOTP = () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        email: email,
        otp: otp,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${base_url}/auth/user/auth/verify/otp/forget/password`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 1) {
            Navigate(`/ForgetPassword/${email}/${otp}`);
            setOtp("");
          }
          else if(result.status==0){
            toast.error(result.msg);
          }
          else if (result.status == "CUSTOM_ERR") {
            toast.error(result.Backend_Error);
          } else if (result.status == "VAL_ERR") {
            toast.error(result.Backend_Error);
          } else {
            console.log(result);
          }
        })
        .catch((error) => console.log("error", error));

        startResendTimer();

    } catch (e) {
      console.log(e);
    }
  };

  const resendOTP = () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        email,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${base_url}/auth/user/auth/forgat/password/by/user`, requestOptions)
        .then((response) => response.json())
        .then((result) => {

          if (result.status == 1) {

            toast.success("otp sended successfully")
            setTimeout(()=>{
                Navigate(`VerifyOTP/${email}`)
            },1000)
            
            
          } else if (result.status == "CUSTOM_ERR") {
            toast.error(result.Backend_Error);
          } else if (result.status == "VAL_ERR") {
            toast.error(result.Backend_Error);
          } else {
            console.log(result);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (e) {
      console.log(e);
    }
  };


  useEffect(() => {
    startResendTimer();
    return () => clearInterval(intervalId);
  }, []); 

  

  return (
    <>
      
      <div
        className="container-fluid m-0 w-100 position-relative"
        style={{ height: "100vh", background: "#F6F6F6", overflowY: "hidden" }}
      >
        <div className="row flex-column justify-content-center h-100">
          {/* navbar started  */}
          <div className="col-12" >
              <Box sx={{ flexGrow: 1 }}>
                <AppBar
                  sx={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    paddingTop: "0.7rem",
                    paddingBottom: "0.7rem",
                  }}
                  className="bg-white px-3 d-flex flex-row align-items-center justify-content-between"
                  elevation={0}
                >
                  <TrendingFlatIcon
                    sx={{ color: "black", transform: "rotate(180deg)" }}
                    onClick={() => {
                      Navigate(-1);
                    }}
                  />
                  <Typography level="h4">Forget Password</Typography>
                  <div></div>
                </AppBar>
              </Box>
            </div>
            {/* navbar end  */}

          <div className="col-12 px-4 d-flex flex-column gap-5">
            <div className="">
              <Typography level="h4">Enter 4 - digit OTP</Typography>
            </div>

            <div>
              <MuiOtpInput
                autoFocus
                value={otp}
                onChange={handleChange}
                validateChar={validateChar}
              />
            <Typography level="body-sm" className="text-end mt-3">
            Don't receive the OTP?{" "}
            <Typography
              level="body-sm"
              color="primary"
              sx={countdown > 0 ? { pointerEvents: 'none', color: '#A0A0A0' } : {}}
              onClick={()=>{
                if(countdown<=0){
                  resendOTP();
                }else{
                  startResendTimer();
                }
              }}
            >
              {countdown > 0 ? `Resend in ${countdown}s` : "Resend"}
            </Typography>
          </Typography>
            </div>
            <Button
              className=" mt-3"
              style={{ background: "#10133f", fontSize: "20px" }}
              sx={{ py: 1 }}
              variant="contained"
              fullWidth={true}
              onClick={verifyOTP}
            >
              Verify OTP
            </Button>
          </div>
          <div className="col-12"></div>
          <div></div>
        </div>
      </div>

      <Toaster position="top-center" />
    </>
  );
};

export default VerifyOTP;

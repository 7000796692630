import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import { useNavigate, useParams } from "react-router-dom";
import Typography from "@mui/joy/Typography";
import Skeleton from "@mui/material/Skeleton";
import toast, { Toaster } from "react-hot-toast";
import { base_url } from "../../env";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import Divider from "@mui/joy/Divider";
import Button from "@mui/joy/Button";
import TimerIcon from "@mui/icons-material/Timer";
import MoveUpIcon from "@mui/icons-material/MoveUp";
const QuizHistory = () => {
  const { id } = useParams();
  const Navigate = useNavigate();
  const [data, setData] = useState([]);
  const [skeleton, setSkeleton] = useState(false);

  const getData = async () => {
    setSkeleton(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("brainbucks_token")}`
      );

      var raw = JSON.stringify({
        quiz_id: id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${base_url}/quiz/prerec_quiz/view/attemped/history/record/prerecquiz`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 1) {
            setData(result.send_data);
          } else if (result.status === "TOKEN_ERR") {
            localStorage.removeItem("brainbucks_token");
            localStorage.removeItem("username");
            Navigate("/");
            toast.error("Logged in other device! Please login again");
          }
        })
        .catch((error) => console.log("error", error))
        .finally(() => {
          setSkeleton(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div
        className="container-fluid w-100"
        style={{ height: "100vh", overflowY: "auto" }}
      >
        <div className="row h-100">
          <div className="col-12 p-0" style={{ overflowX: "hidden" }}>
            {/* navbar started  */}
            <Box sx={{ flexGrow: 1 }}>
              <AppBar
                sx={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  paddingTop: "0.7rem",
                  paddingBottom: "0.7rem",
                }}
                className="bg-white px-3 d-flex flex-row align-items-center justify-content-between"
                elevation={0}
              >
                <TrendingFlatIcon
                  sx={{ color: "black", transform: "rotate(180deg)" }}
                  onClick={() => {
                    Navigate(-1);
                  }}
                />
                <Typography level="title-lg">Quiz</Typography>
                <div></div>
              </AppBar>
            </Box>
            {/* navbar end  */}

            <div className="row px-3 py-5" style={{ rowGap: "10px" }}>
              {data.length > 0 ? (
                data
                  ?.slice()
                  .reverse()
                  .map((data) => (
                    <div className="col-12" key={data._id}>
                      <Card variant="outlined" className="h-100">
                        <CardContent className="quiz-card">
                          {skeleton == true ? (
                            <>
                              <Skeleton
                                variant="text"
                                sx={{ fontSize: "1rem", width: "100%" }}
                              />
                              <Skeleton
                                variant="text"
                                sx={{ fontSize: "1rem", width: "100%" }}
                              />
                            </>
                          ) : (
                            <>
                              <Typography level="title-md" >
                                Result{" "} : &nbsp; 
                                 {data.result &&
                                   data.result.charAt(0).toUpperCase() +
                                     data.result.slice(1)}
                              </Typography>

                              <Typography level="body-sm">
                                Time Taken : {data.submit_timeperiod}s
                              </Typography>
                              <Typography level="body-sm">
                                Score : {data.marks_score}
                              </Typography>
                              <Typography level="body-sm">
                                Percentage  : {data.persentage_score}%
                              </Typography>
                            </>
                          )}
                        </CardContent>
                        <CardOverflow
                          variant="soft"
                          sx={{ bgcolor: "background.level1" }}
                        >
                          {skeleton == true ? (
                            <>
                              <Divider inset="context" />
                              <CardContent orientation="horizontal">
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "1rem", width: "25%" }}
                                />
                                <Divider orientation="vertical" />
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "1rem", width: "25%" }}
                                />
                                <div className="w-100 d-flex justify-content-end">
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1rem", width: "50%" }}
                                  />
                                </div>
                              </CardContent>
                            </>
                          ) : (
                            <>
                              <Divider inset="context" />
                              <CardContent orientation="horizontal">
                                <Typography
                                  level="body-xs"
                                  fontWeight="md"
                                  color="success"
                                  className="d-flex align-items-center gap-1"
                                  
                                >
                                  {/* <MoveUpIcon sx={{ fontSize: "1rem" }} /> */}
                                  {data.correct}
                                </Typography>
                                <Divider orientation="vertical" />
                                <Typography
                                  level="body-xs"
                                  fontWeight="md"
                                  color="warning"
                                  className="d-flex align-items-center gap-1"
                                >
                                  {data.unattemped}
                                </Typography>
                                <Divider orientation="vertical" />
                                <Typography
                                  level="body-xs"
                                  fontWeight="md"
                                  color="danger"
                                  className="d-flex align-items-center gap-1"
                                >
                                  {data.wrong}
                                </Typography>
                              </CardContent>
                            </>
                          )}
                        </CardOverflow>
                      </Card>
                    </div>
                  ))
              ) : (
                <h5 className="text-center d-flex h-100 mt-5 justify-content-center align-items-center">
                  No data found !
                </h5>
              )}
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </>
  );
};

export default QuizHistory;

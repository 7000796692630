import React from "react";
import BottomMenu from "../bottom-navigation/BottomMenu";
import TestData from "./TestData";
import TestNavbar from "./TestNavbar";

const Test = () => {
  return (
    <>
      <div
        className="container-fluid w-100"
        style={{ height: "100vh", overflowY: "auto" }}
      >
        <div className="row h-100">
          <div className="col-12 p-0">
            <div>
              <TestNavbar />
            </div>

            <div className="py-5 mb-3 px-3 h-100" >
              <TestData />
            </div>

            <div>
              <BottomMenu />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Test;

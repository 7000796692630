import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import { useNavigate, useParams } from "react-router-dom";
import Typography from "@mui/joy/Typography";
import { base_url } from "../../env";
import { toast, Toaster } from "react-hot-toast";
import { Button, Divider, Paper, Skeleton } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

const style = {
  p: 0,
  width: "100%",
  //   maxWidth: 360,
  borderRadius: 2,
  border: "1px solid",
  borderColor: "divider",
  backgroundColor: "background.paper",
};

const QuizDetailed = () => {
  const Navigate = useNavigate();
  const { id } = useParams();
  const { video_id } = useParams();
  const [data, setData] = useState({});

  const getQuizData = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("brainbucks_token")}`
      );

      var raw = JSON.stringify({
        quiz_id: id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${base_url}/quiz/prerec_quiz/view/quiz/detail/by/user`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 1) {
            setData(result.quiz_data);
          } else if (result.status === "TOKEN_ERR") {
            localStorage.removeItem("brainbucks_token");
            localStorage.removeItem("username");
            Navigate("/");
            toast.error("Logged in other device! Please login again");
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getQuizData();
  }, []);

  return (
    <>
      <div
        className="container-fluid w-100"
        style={{ height: "100vh", overflowY: "auto" }}
      >
        
        <div className="row h-100">
          <div className="col-12 p-0">
            {/* navbar started  */}
            <div>
              <Box sx={{ flexGrow: 1 }}>
                <AppBar
                  sx={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    paddingTop: "0.7rem",
                    paddingBottom: "0.7rem",
                  }}
                  className="bg-white px-3 d-flex flex-row align-items-center justify-content-between"
                  elevation={0}
                >
                  <TrendingFlatIcon
                    sx={{ color: "black", transform: "rotate(180deg)" }}
                    onClick={() => {
                      Navigate(-1);
                    }}
                  />
                  <Typography level="title-lg">Quiz Details</Typography>
                  <div></div>
                </AppBar>
              </Box>
            </div>
            {/* navbar end  */}

            {/* main content started  */}
            <div className="py-5 mb-3 px-3">
              <Divider />
              <div className=" mt-2">
                <List sx={style} aria-label="mailbox folders">
                  <ListItem className="flex-column align-items-start">
                    <Typography variant="" noWrap level="body-xs">
                      Name of Quiz
                    </Typography>
                    {data.quiz_name ? (
                      <Typography variant=""  level="h4">
                        {data.quiz_name}
                      </Typography>
                    ) : (
                      <Skeleton variant="text" level="h4" />
                    )}
                  </ListItem>

                  <Divider component="li" light />

                  <ListItem className="flex-column align-items-start">
                    <Typography variant="" noWrap level="body-xs">
                      Course Name
                    </Typography>
                    {data.prerec_couname ? (
                      <Typography variant=""   level="h4">
                        {data.prerec_couname}
                      </Typography>
                    ) : (
                      <Skeleton variant="text" level="h4" />
                    )}
                  </ListItem>

                  <Divider component="li" light />

                  <ListItem className="justify-content-between align-items-center">
                    <div>
                      <Typography variant="" noWrap level="body-xs">
                        No of Questions
                      </Typography>
                      {data.number_of_question ? (
                        <Typography variant="" noWrap level="h4">
                          {data.number_of_question}
                        </Typography>
                      ) : (
                        <Skeleton variant="text" level="h4" />
                      )}
                    </div>

                    <div>
                      <Typography variant="" noWrap level="body-xs">
                        Time per Questions
                      </Typography>
                      {data.time_per_question ? (
                        <Typography variant="" noWrap level="h4">
                          {data.time_per_question}s
                        </Typography>
                      ) : (
                        <Skeleton variant="text" level="h4" />
                      )}
                    </div>
                  </ListItem>

                  <Divider component="li" light />
                  
                  <ListItem className="flex-column align-items-start">
                    <Typography variant="" noWrap level="body-xs">
                      Video Name
                    </Typography>
                    {data.prerec_videoname ? (
                      <Typography variant="" noWrap level="h4">
                        {data.prerec_videoname}
                      </Typography>
                    ) : (
                      <Skeleton variant="text" level="h4" />
                    )}
                  </ListItem>

                  <Divider component="li" light />
                  
                   <ListItem className="justify-content-between align-items-center">
                    <div>
                      <Typography variant="" noWrap level="body-xs">
                       Marks per Questions
                      </Typography>
                      {data.marks_per_question ? (
                        <Typography variant="" noWrap level="h4">
                          {data.marks_per_question}
                        </Typography>
                      ) : (
                        <Skeleton variant="text" level="h4" />
                      )}
                    </div>

                    <div>
                      <Typography variant="" noWrap level="body-xs">
                        Passing Percentage
                      </Typography>
                      {data.pass_pers ? (
                        <Typography variant="" noWrap level="h4">
                          {data.pass_pers}%
                        </Typography>
                      ) : (
                        <Skeleton variant="text" level="h4" />
                      )}
                    </div>
                  </ListItem>

                  {data.topics_ratio && (
              <>
                <Divider component="li" light />

                <ListItem className="flex-column align-items-start">
                  <Typography variant="" noWrap level="body-xs">
                    Topics Ratio
                  </Typography>

                  <List>
                    {Object.entries(data.topics_ratio).map(([topic, ratio]) => (
                      <ListItem
                        key={topic}
                        className="w-100"
                      >
                         <div className="d-flex align-items-center justify-content-between w-100">
                          {topic ? (
                            <Typography variant=""  level="h4">
                              {topic}
                            </Typography>
                          ) : (
                            <Skeleton variant="text" level="h4" />
                          )}
                          {ratio ? (
                            <Typography variant=""  level="h4">
                              {ratio}%
                            </Typography>
                          ) : (
                            <Skeleton variant="text" level="h4" />
                          )}
                        </div>
                      </ListItem>
                    ))}
                  </List>
                </ListItem>
              </>
            )}


            <Divider component="li" light />
                  
                  <ListItem className="flex-column align-items-start">
                    <Typography variant="" noWrap level="body-xs">
                      Quiz Rules
                    </Typography>
                    {data.quiz_rules ? (
                      <Typography variant="" noWrap level="h4">
                        {data.quiz_rules}
                      </Typography>
                    ) : (
                      <Skeleton variant="text" level="h4" />
                    )}
                  </ListItem>

            
                  

                </List>
              </div>
            </div>
            {/* main content end  */}

            <div>
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={0}>
                <div className="d-flex p-2 gap-3 align-items-center" >
                    <Button variant="contained" className="flex-grow-1 py-2" onClick={()=>Navigate(`/AttemptQuiz/${id}`)} >Start Quiz</Button>
                    <Button variant="outlined" className="flex-grow-1 py-2" onClick={()=>Navigate(`/QuizHistory/${id}`)} >Quiz History</Button>
                </div>
            </Paper>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </>
  );
};

export default QuizDetailed;

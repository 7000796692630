import React, { useState } from "react";
import TrendingSlider from "./TrendingSlider";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import PaidCourses from "./PaidCourses";
import FreeCourses from "./FreeCourses";
import { username } from "../env";
import { useNavigate } from "react-router-dom";

const HomeData = () => {
  const Navigate = useNavigate();
  return (
    <>
      {/* welcome user heading started */}
      <h2>Welcome {username}</h2>
      {/* welcome user heading end  */}

      {/* trending slider started  */}
      <TrendingSlider />
      {/* trending slider end  */}

      {/* paid courses section started   */}
      <div className="d-flex mt-3 mb-1 align-items-center justify-content-between">
        <h4>Paid Course's</h4>
        <h6 onClick={()=>{Navigate("/SeeAllPaidCourses")}} style={{ color: "gray" }}>
          See All <TrendingFlatIcon />{" "}
        </h6>
      </div>

      <PaidCourses/>
      {/* paid courses section end  */}

      {/* free courses section started   */}
      <div className="d-flex mt-3 mb-1 align-items-center justify-content-between">
      <div>
        <h4 className="mb-0" style={{color:"#10133f"}} >Enroll Free</h4>
        <h6 className="mb-0" >Boost Your Productivity</h6>
      </div>
        <h6 style={{ color: "gray" }} onClick={()=>{Navigate("/SeeAllFreeCourses")}}  >
          See All <TrendingFlatIcon />{" "}
        </h6>
      </div>

      <FreeCourses/>
      {/* free courses section end  */}
    </>
  );
};

export default HomeData;

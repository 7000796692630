import React,{useState,useEffect} from 'react'
import { FaRegEye } from "react-icons/fa";
import { useNavigate, useParams } from 'react-router-dom';
import { base_url } from '../env';
import { toast, Toaster } from "react-hot-toast";

const Mocks = ({mocks}) => {
  const {id} = useParams();
  const Navigate = useNavigate();
  const [quizzes, setQuizzes] = useState([]);
  const getQuizzes = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("brainbucks_token")}`
      );

      var raw = JSON.stringify({
        prerec_couid: parseInt(id),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${base_url}/quiz/prerec_quiz/get/quiz/in/particular/course/by/user`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 1) {
            setQuizzes(result.quizes);
          } else if (result.status === "TOKEN_ERR") {
            localStorage.removeItem("brainbucks_token");
            localStorage.removeItem("username");
            Navigate("/");
            toast.error("Logged in other device! Please login again");
          }
        })
        .catch((error) => console.log("error", error))
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getQuizzes();
  }, [])
  

  return (
    <>
        <div className='p-0 w-full'>


<ul className='w-full p-0'>
{quizzes.length == 0 ? (
                        <>
                          <h3 style={{ color: "red" }}>
                            No Quizzes to show
                          </h3>
                        </>
                      ) : (
                        <>
  {quizzes?.slice().reverse().map((note, index) => (


    <li key={index} style={{listStyle:"none"}}>
      <div className='p-2 mb-2 align-items-center shadow rounded d-flex flex-row justify-content-between ' style={{border:"1px solid black",}}>
      
      <div className='mb-0' >{note.quiz_name}</div>
      <div>{<FaRegEye size={"1rem"}  onClick={()=>Navigate(`/QuizDetails/${note._id}`)} />}</div>


      </div>
      </li>

  ))}
  </>)}


</ul>



</div>
<Toaster/>
    </>
  )
}

export default Mocks
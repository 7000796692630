import React from 'react'
import PdfNavbar from './PdfNavbar'
import PdfData from './PdfData'
import BottomMenu from '../bottom-navigation/BottomMenu'

const Pdf = () => {
  return (
    <>
        <div className="container-fluid w-100" style={{height:"100vh",overflowY:"auto"}} >
        <div className="row h-100">
          <div className="col-12 p-0">
            <div>
              <PdfNavbar />
            </div>

            <div className="py-5 mb-3 px-3 h-100" >
                <PdfData/>
            </div>

            <div>
              <BottomMenu />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Pdf

import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Typography from '@mui/joy/Typography';
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import toast, { Toaster } from "react-hot-toast";
import {base_url } from ".././env"

const ForgetPassword = () => {
    
    const [email, setEmail] = useState("");
    const Navigate = useNavigate();

    
  const sendOTP = () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        email,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${base_url}/auth/user/auth/forgat/password/by/user`, requestOptions)
        .then((response) => response.json())
        .then((result) => {

          if (result.status == 1) {

            toast.success("otp sended successfully")
            setTimeout(()=>{
                Navigate(`VerifyOTP/${email}`)
            },1000)
            
            
          }else if(result.status==0){
            toast.error(result.msg);
          }
          else if (result.status == "CUSTOM_ERR") {
            toast.error(result.Backend_Error);
          } else if (result.status == "VAL_ERR") {
            toast.error(result.Backend_Error);
          } else {
            console.log(result);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (e) {
      console.log(e);
    }
  };




  return (
    <>

   
      <div
        className="container-fluid m-0 w-100 position-relative"
        style={{ height: "100vh", background: "#F6F6F6", overflowY: "hidden" }}
      >
        <div className="row flex-column justify-content-center h-100">
          {/* navbar started  */}
          <div className="col-12" >
              <Box sx={{ flexGrow: 1 }}>
                <AppBar
                  sx={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    paddingTop: "0.7rem",
                    paddingBottom: "0.7rem",
                  }}
                  className="bg-white px-3 d-flex flex-row align-items-center justify-content-between"
                  elevation={0}
                >
                  <TrendingFlatIcon
                    sx={{ color: "black", transform: "rotate(180deg)" }}
                    onClick={() => {
                      Navigate(-1);
                    }}
                  />
                  <Typography level="h4">Forget Password</Typography>
                  <div></div>
                </AppBar>
              </Box>
            </div>
            {/* navbar end  */}



          <div className="col-12 px-4 d-flex flex-column gap-3">
            <div className="">
              <Typography level="h4" >Forgot Password</Typography>
            </div>

  

            <TextField
              value={email}
                id="outlined-email"
                onChange={(e) => setEmail(e.target.value)}
                label="Email"
                variant="outlined"
                type={"email"}
                fullWidth={true}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              />
            <Button
              className=" mt-3"
              style={{ background: "#10133f" , fontSize:"20px"}}
              sx={{ py: 1 }}
              variant="contained"
              fullWidth={true}
              onClick={()=>{sendOTP()
               
            }}
              
            >
              Send OTP
            </Button>

          </div>
          <div className="col-12"></div>
          <div></div>
        </div>
      </div>

      <Toaster position="top-center" />
    </>
  )
}

export default ForgetPassword
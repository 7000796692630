import React, { useEffect, useState,useContext } from "react";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Typography from "@mui/joy/Typography";
import { base_url } from "../../env";
import { toast, Toaster } from "react-hot-toast";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import { useTheme } from "@mui/material/styles";
import Radio from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Chip from "@mui/material/Chip";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FastForwardIcon from "@mui/icons-material/FastForward";
import SendIcon from "@mui/icons-material/Send";
import Stack from "@mui/material/Stack";
import { QuizContext } from "../../../App";

const AttemptQuiz = () => {
  const [render,setRender] = useState(0);
  const Navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [counter, setCounter] = useState(10);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState(
    Array(data.length).fill(-1)
  );
  const [mainId, setMainId] = useState("");
  const [startTime, setStartTime] = useState(0);
  const { setQuizResult } = useContext(QuizContext);

  const theme = useTheme();

  const startQuiz = () => {
    const currentTime = Math.floor(Date.now() / 1000);
    setStartTime(currentTime);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleOptionChange = (event, index) => {
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[index] = event.target.value || -1;
    setSelectedOptions(newSelectedOptions);
  };

  const skipQuiz = () => {
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[activeStep] = -1;
    setSelectedOptions(newSelectedOptions);
    if (activeStep === data.length - 1) {
      return;
    } else {
      handleNext();
    }
  };

  const quiz = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("brainbucks_token")}`
      );

      var raw = JSON.stringify({
        quiz_id: id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${base_url}/quiz/prerec_quiz/attemped/prerecquiz`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status === 1) {
            setData(result.data.send_questions);
            setCounter(result.data.time_period);
            setMainId(result.data.main_id);
            setSelectedOptions(result.data.send_answer);
            startQuiz();
          } else if (result.status === "TOKEN_ERR") {
            localStorage.removeItem("brainbucks_token");
            localStorage.removeItem("username");
            Navigate("/");
            toast.error("Logged in other device! Please login again");
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };

  const submitQuiz = async () => {
    try {
      const currentTime = Math.floor(Date.now() / 1000);
      const timeTaken = currentTime - startTime;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("brainbucks_token")}`
      );

      var raw = JSON.stringify({
        main_id: mainId,
        quiz_id: id,
        submit_timeperiod: timeTaken,
        user_answer: selectedOptions,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${base_url}/quiz/prerec_quiz/submit/prerecquiz`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 1) {
            // toast.success("Quiz Submited Successfully");
            setData([]);
            setQuizResult(result);
            Navigate(`/QuizResult`);
          } else if (result.status === "TOKEN_ERR") {
            localStorage.removeItem("brainbucks_token");
            localStorage.removeItem("username");
            Navigate("/");
            toast.error("Logged in other device! Please login again");
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(()=>{
    quiz();
  },[render])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCounter((prevCounter) => prevCounter - 1);
    }, 1000);

    return () => {
      clearInterval(intervalId);
      if (counter <= 0) {
        submitQuiz();
      }
    };
  }, [counter]);

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const minutesStr = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const secondsStr =
      remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
    return `${minutesStr}:${secondsStr}`;
  }

  return (
    <>
      <div
        className="container-fluid w-100"
        style={{ height: "100vh", overflowY: "auto" }}
      >
        
        <div className="row h-100">
          <div className="col-12 p-0 d-flex flex-column justify-content-around ">
            {/* navbar started  */}
            <div>

              {data.length <= 0 ? (
                <>
                  <Box sx={{ flexGrow: 1 }}>
                    <AppBar
                      sx={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        paddingTop: "0.7rem",
                        paddingBottom: "0.7rem",
                      }}
                      className="bg-white px-3 d-flex flex-row align-items-center justify-content-between"
                      elevation={0}
                    >
                      <TrendingFlatIcon
                        sx={{ color: "black", transform: "rotate(180deg)" }}
                        onClick={() => {
                          Navigate(-1);
                        }}
                      />
                      <Typography level="title-lg">BrainBucks Quiz</Typography>
                      <div></div>
                    </AppBar>
                  </Box>
                </>
              ) : (
                <>
                  <Box sx={{ flexGrow: 1 }}>
                    <AppBar
                      sx={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                      }}
                      className="bg-white d-flex flex-row align-items-center justify-content-between"
                      elevation={0}
                    >
                      <MobileStepper
                        variant="progress"
                        steps={data.length}
                        position="static"
                        activeStep={activeStep}
                        sx={{ flexGrow: 1 }}
                        nextButton={
                          <Button
                            size="small"
                            onClick={handleNext}
                            disabled={activeStep === data.length - 1}
                          >
                            Next
                            {theme.direction === "rtl" ? (
                              <KeyboardArrowLeft />
                            ) : (
                              <KeyboardArrowRight />
                            )}
                          </Button>
                        }
                        backButton={
                          <Button
                            size="small"
                            onClick={handleBack}
                            disabled={activeStep === 0}
                          >
                            {theme.direction === "rtl" ? (
                              <KeyboardArrowRight />
                            ) : (
                              <KeyboardArrowLeft />
                            )}
                            Back
                          </Button>
                        }
                      />
                    </AppBar>
                  </Box>
                </>
              )}
            </div>
            {/* navbar end  */}

            <div className="text-center d-flex justify-content-center">
              {data.length <= 0 ? (
                <></>
              ) : (
                <>
                  <Chip
                    variant="outlined"
                    color={
                      counter <= 5
                        ? "error"
                        : counter <= 30
                        ? "warning"
                        : "primary"
                    }
                    label={counter}
                    icon={<AccessTimeIcon />}
                    className={counter <= 5 ? "pulse-animation" : ""}
                  />
                </>
              )}
            </div>

            <div className=" px-3">
              <div className="d-flex flex-column h-100 justify-content-center">
                {data.length > 0 && activeStep < data.length ? (
                  <div key={data[activeStep]._id}>
                    <Typography
                      level="h5"
                      className="mb-2"
                      sx={{ wordWrap: "break-word" }}
                    >
                      Q{activeStep + 1}. {data[activeStep].question}
                    </Typography>
                    <RadioGroup
                      aria-label={`Your plan ${activeStep}`}
                      name={`people${activeStep}`}
                      value={selectedOptions[activeStep]}
                      onChange={(event) =>
                        handleOptionChange(event, activeStep)
                      }
                    >
                      <List
                        sx={{
                          "--List-gap": "0.5rem",
                          "--ListItem-paddingY": "1rem",
                          "--ListItem-radius": "8px",
                          "--ListItemDecorator-size": "32px",
                        }}
                      >
                        {["Option 1", "Option 2", "Option 3", "Option 4"].map(
                          (option, optionIndex) => (
                            <ListItem
                              variant="outlined"
                              key={option}
                              sx={{ boxShadow: "sm", wordWrap: "break-word" }}
                            >
                              <Radio
                                style={{ wordWrap: "wrap !important" }}
                                overlay
                                value={optionIndex + 1}
                                label={option}
                                sx={{ flexGrow: 1, flexDirection: "row" }}
                                slotProps={{
                                  action: ({ checked }) => ({
                                    sx: (theme) => ({
                                      ...(checked && {
                                        inset: -1,
                                        border: "2px solid",
                                        borderColor:
                                          theme.vars.palette.primary[500],
                                        wordWrap: "wrap !important",
                                      }),
                                    }),
                                  }),
                                }}
                              />
                            </ListItem>
                          )
                        )}
                      </List>
                    </RadioGroup>
                  </div>
                ) : (
                  <Typography
                    level="h3"
                    className="text-center"
                    sx={{ wordWrap: "break-word" }}
                  >
                    No questions found.
                  </Typography>
                )}
              </div>
            </div>

            <div className="text-center d-flex justify-content-center">
              {data.length <= 0 ? (
                <></>
              ) : (
                <>
                  <Chip
                    className="px-2"
                    variant="outlined"
                    color="primary"
                    label={`${activeStep + 1}/${data.length}`}
                  />
                </>
              )}
            </div>

            <div>
              {data.length <= 0 ? (
                <></>
              ) : (
                <>
                  <Stack direction="row" spacing={2} sx={{ px: 2 }}>
                    <Button
                      onClick={skipQuiz}
                      variant="outlined"
                      fullWidth={true}
                      startIcon={<FastForwardIcon />}
                    >
                      Skip
                    </Button>
                    {
                      activeStep==data.length-1?(
                        <>

                    <Button
                      variant="contained"
                      fullWidth={true}
                      endIcon={<SendIcon />}
                      onClick={submitQuiz}
                    >
                      submit
                    </Button>
                        </>
                      ):(
                       <>

                    <Button
                      variant="contained"
                      fullWidth={true}
                      endIcon={<SendIcon />}
                      onClick={handleNext}
                    >
                      Save & Next
                    </Button>
                       </> 
                      )
                    }
                  </Stack>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </>
  );
};

export default AttemptQuiz;

import React,{useEffect, useState} from "react";
import Card from "@mui/material/Card";
import Carousel from 'react-bootstrap/Carousel';
import Skeleton from '@mui/material/Skeleton';
import {base_url } from "../env"
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";



const TrendingSlider = () => {
  const Navigate = useNavigate();
  const [banner,setBanner] = useState([]);
  const [skeleton,setSkeleton] = useState(false);

  const getBanners = async()=>{
    try {
      var myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${localStorage.getItem("brainbucks_token")}`);

var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};
setSkeleton(true)
fetch(`${base_url}/auth/banner/view/banner/by/user`, requestOptions)
  .then(response => response.json())
  .then(result => {
    setSkeleton(false);
    if(result.status==1){
      setBanner(result.banners)
    }else if (result.status === 'VAL_ERR') { 
      toast.error(result.Backend_Error) 
    } 
    else if (result.status == "CUSTOM_ERR") {
      toast.error(result.Backend_Error);
    } 
    else if (result.status == "TOKEN_ERR") {
      localStorage.removeItem('brainbucks_token');
      localStorage.removeItem("username")
      Navigate('/')
      toast.error("Logged in other device! Please login again")
    }
  })
  .catch(error => console.log('error', error));
    } catch (error) {
      console.log(error)
    }
  }

  console.log(banner)


  useEffect(() => {
    getBanners();
  }, [])
  

  return (
    <>
    
      <Card elevation={0}>

        <Carousel style={{ height: "135px" }} className="w-100" indicators={false}  controls={false}>
  {banner && banner.length > 0 ? (
    banner?.slice().reverse().map((data, value) => (
      <Carousel.Item interval={1000} key={value} style={{ height: "135px" }}>
        <img
          key={value}
          src={`${data.url}`}
          className="d-block w-100"
          style={{ height: "100%", objectFit: "cover", objectPosition: "center center" }}
          alt={`Slide ${value}`}
        />
      </Carousel.Item>
    ))
  ) : (
    <Skeleton variant="rounded" className="w-100" height={135} />
  )}
</Carousel>


      </Card>



      <Toaster position="top-center" />
    </>
  );
};

export default TrendingSlider;

import React, { useState , useEffect} from "react";
import BottomMenu from "../bottom-navigation/BottomMenu";
import MyCoursesNavbar from "./MyCoursesNavbar";
import { useParams ,useNavigate} from "react-router-dom";
import { base_url, token } from "../env";
import AspectRatio from "@mui/joy/AspectRatio";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import Typography from "@mui/joy/Typography";
import Lessons from "./Lessons";
import Notes from "./Notes";
import Mocks from "./Mocks";
import toast,{Toaster} from "react-hot-toast"

const ViewCourse = () => {
  const Navigate = useNavigate();
  const { title, banner, price, id } = useParams();
  const [activeTab, setActiveTab] = useState("tab1");
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };


  const [notes , setNotes] = useState([])
  const [lessons , setLessons] = useState([])
  const [mocks , setMocks] = useState([])
  const [vidId,setVidId] = useState([]);
  const [data,setData] = useState({});




  const getNotes =() => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("brainbucks_token")}`
        );
        myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: JSON.stringify({
          prereccou_id : Number(id) ,
        }),
      };

      fetch(`${base_url}/prerec/studymaterails/get/study/materials/of/particular/course/byuser`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 1) {
            
            setNotes(result.study_materials)
            console.log(notes)

          } 
          else if (result.status === "VAL_ERR") {
            toast.error(result.Backend_Error);
          } else if (result.status == "CUSTOM_ERR") {
            toast.error(result.Backend_Error);
          } else if (result.status == "TOKEN_ERR") {
            localStorage.removeItem("brainbucks_token");
            localStorage.removeItem("username");
            Navigate("/");
            toast.error("Logged in other device! Please login again");
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };





// for mocks
  const getMocks =async() => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("brainbucks_token")}`
        );
        myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: JSON.stringify({
          prerec_couid : Number(id)  
        }),
      };

      fetch(`${base_url}/quiz/prerec_quiz/get/quiz/in/particular/course/by/user`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 1) {
            
            setMocks(result.quizes)
            console.log(mocks)

          } 
          else if (result.status === "VAL_ERR") {
            toast.error(result.Backend_Error);
          } else if (result.status == "CUSTOM_ERR") {
            toast.error(result.Backend_Error);
          } else if (result.status == "TOKEN_ERR") {
            localStorage.removeItem("brainbucks_token");
            localStorage.removeItem("username");
            Navigate("/");
            toast.error("Logged in other device! Please login again");
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };




  // fir lessons
  const getVideos =async() => {
    
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("brainbucks_token")}`
        );
        myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: JSON.stringify({
          prerec_cou_id : Number(id),
        }),
      };

      fetch(`${base_url}/prerec/prerecordedcourse/view/vedios/of/course/by/user`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 1) {
            
            setLessons(result.videos)

          } 
          else if (result.status === "VAL_ERR") {
            toast.error(result.Backend_Error);
          } else if (result.status == "CUSTOM_ERR") {
            toast.error(result.Backend_Error);
          } else if (result.status == "TOKEN_ERR") {
            localStorage.removeItem("brainbucks_token");
            localStorage.removeItem("username");
            Navigate("/");
            toast.error("Logged in other device! Please login again");
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };



  const courseDetails = async()=>{
    try {
      var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", `Bearer ${localStorage.getItem("brainbucks_token")}`);

var raw = JSON.stringify({
  "prerec_cou_id": Number(id)
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(`${base_url}/prerec/prerecordedcourse/view/detail/of/course/by/user`, requestOptions)
  .then(response => response.json())
  .then(result => {
    setData(result.sen_data);
  })
  .catch(error => console.log('error', error));
    } catch (error) {
      console.log(error);
    }
  }

  


  useEffect(()=>{
    getNotes()
    getVideos()
    getMocks()
    courseDetails();
  }, [])

  return (
    <>
      <div
        className="container-fluid w-100"
        style={{ height: "100vh", overflowY: "auto" }}
      >
      
        <div className="row">
          <div className="col-12 p-0">
            <div>
              <MyCoursesNavbar />
            </div>

            <div className="py-5 mb-3 px-3" >
              {/* main content */}

              <Card
                // elevation={0}
                sx={{
                  // height: 230,
                  // marginBottom: 2,
                  // backgroundColor: "#CFE2FF",
                  marginBottom: 3,
                }}
              >
              <CardOverflow>
        <AspectRatio ratio="2">
          <img
            src={`${data.banner}`}
            loading="lazy"
            alt=""
          />
        </AspectRatio>
      </CardOverflow>

      <CardContent sx={{alignItems:"start !important"}} >
        <Typography level="title-lg"  sx={{wordBreak:"break-word"}}> {data.cou_name}</Typography>
        <Typography level="body-md"> ₹ {data.total_amount}</Typography>
      </CardContent>
              </Card>

              {/* tabs */}

              <div className="px-2">
                <div
                  className="btn-group mb-3"
                  style={{
                    display: "flex",
                    gap: 5,
                    justifyContent: "space-around",
                  }}
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    style={{
                      border: `1px solid black
          
          
          
          `,
                    }}
                    className={`btn ${
                      activeTab === "tab1" ? "btn-primary" : "bg-white"
                    }`}
                    onClick={() => handleTabClick("tab1")}
                  >
                    Lesson
                  </button>
                  <button
                    type="button"
                    style={{ border: `1px solid black` }}
                    className={`btn ${
                      activeTab === "tab2" ? "btn-primary" : "bg-white"
                    } `}
                    onClick={() => handleTabClick("tab2")}
                  >
                    Notes
                  </button>
                  <button
                    style={{ border: `1px solid black` }}
                    type="button"
                    className={`btn ${
                      activeTab === "tab3" ? "btn-primary" : "bg-white"
                    }`}
                    onClick={() => handleTabClick("tab3")}
                  >
                    Live Mocks
                  </button>
                </div>

                {/* Content for the tabs */}
                {activeTab === "tab1" && (
                  <div>
                    <Lessons lessons={lessons} />
                  </div>
                )}
                {activeTab === "tab2" && (
                  <div>
                    <Notes notes={notes} />
                  </div>
                )}
                {activeTab === "tab3" && (
                  <div>
                    <Mocks mocks={mocks} />
                  </div>
                )}
              </div>
            </div>

            <div>
              <BottomMenu />
            </div>
          </div>
        </div>
      </div>
      <Toaster/>
    </>
  );
};

export default ViewCourse;

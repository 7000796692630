import React, { useEffect } from 'react'
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import HomeIcon from '@mui/icons-material/Home';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import PersonIcon from '@mui/icons-material/Person';
import Paper from '@mui/material/Paper';
import {useLocation, useNavigate} from "react-router-dom";
import FileCopyIcon from '@mui/icons-material/FileCopy';


const BottomMenu = () => {
  const Navigate = useNavigate();
  const location = useLocation();
    const [value, setValue] = React.useState();

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    
    useEffect(() => {
      if (location.pathname === "/Home") {
        setValue("Home");
      } else if (location.pathname === "/MyCourses") {
        setValue("Courses");
      } else if (location.pathname === "/Test") {
        setValue("Test");
      } else if (location.pathname === "/Pdf") {
        setValue("PDF");
      }else if (location.pathname === "/Profile") {
        setValue("Profile");
      }
    }, [location.pathname]);
  return (
    <>
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, }} elevation={0}>
    <BottomNavigation value={value} onChange={handleChange}  >
      <BottomNavigationAction
      onClick={()=>Navigate("/Home")}
        label="Home"
        value="Home"
        icon={<HomeIcon />}
      />
      <BottomNavigationAction
      onClick={()=>Navigate("/MyCourses")}
        label="Courses"
        value="Courses"
        icon={<LocalLibraryIcon />}
      />
      <BottomNavigationAction
      onClick={()=>Navigate("/Pdf")}
        label="PDF"
        value="PDF"
        icon={<FileCopyIcon />}
      />
      <BottomNavigationAction
      onClick={()=>Navigate("/Test")}
        label="Test"
        value="Test"
        icon={<AssignmentTurnedInIcon />}
      />
      <BottomNavigationAction
      onClick={()=>Navigate("/Profile")}
        label="Profile"
        value="Profile"
        icon={<PersonIcon />}
      />
      {/* <BottomNavigationAction label="Folder" value="folder" icon={<FolderIcon />} /> */}
    </BottomNavigation>
    </Paper>
    </>
  )
}

export default BottomMenu

import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { base_url, token } from "../env";
import { CardActionArea } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { useNavigate } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import { motion } from "framer-motion";

const PdfData = () => {
  const Navigate = useNavigate();
  const [data, setData] = useState([]);
  const [skeleton, setSkeleton] = useState(false);
  const getCourses = async () => {
    setSkeleton(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("brainbucks_token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${base_url}/prerec/studymaterails/view/pdf/mocktest/tab/byuser`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === 1) {
            setData(result.courses);
          } else if (result.status === "TOKEN_ERR") {
            localStorage.removeItem("brainbucks_token");
            localStorage.removeItem("username");
            Navigate("/");
            toast.error("Logged in other device! Please login again");
          }
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          setSkeleton(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCourses();
  }, []);

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <>
      <motion.div
        className={`row ${data.length>0?'':'h-100'}`}
        style={{ rowGap: "10px" }}
        variants={container}
        initial="hidden"
        animate="visible"
      >
        {data.length > 0 ? (
          data
            ?.slice()
            .reverse()
            .map((course) => (
              <motion.div key={course.id} className="col-4" variants={item}>
                <Card
                  className=""
                  onClick={() => Navigate(`/StudyMaterial/${course.id}`)}
                >
                  <CardActionArea>
                    {skeleton == true ? (
                      <Skeleton variant="rectangular" height={90} />
                    ) : (
                      <CardMedia
                        component="img"
                        height="90"
                        image={`${course.banner}`}
                        alt={course.name}
                      />
                    )}
                    <CardContent className="p-0 px-2">
                      <Typography
                        gutterBottom
                        variant="h6"
                        noWrap
                        className="mb-0"
                        component="div"
                      >
                        {skeleton ? (
                          <Skeleton variant="text" />
                        ) : (
                          course.cou_name
                        )}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </motion.div>
            ))
        ) : (
          <h5 className="text-center d-flex h-100 justify-content-center align-items-center">
            No data found !
          </h5>
        )}
      </motion.div>
      <Toaster />
    </>
  );
};

export default PdfData;

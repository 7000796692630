import React from "react";
import BottomMenu from "../bottom-navigation/BottomMenu";
import Navbar from "../navbar/Navbar";
import HomeData from "./HomeData";

const Home = () => {
  return (
    <>
      <div className="container-fluid w-100" style={{height:"100vh",overflowY:"auto"}} >
        <div className="row">
          <div className="col-12 p-0">
            <div>
              <Navbar />
            </div>

            <div className="py-5 mb-3 px-3" >
                <HomeData/>
            </div>

            <div>
              <BottomMenu />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { base_url, token } from "../../env";
import { toast, Toaster } from "react-hot-toast";
import Skeleton from "@mui/material/Skeleton";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import Divider from "@mui/joy/Divider";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import TimerIcon from '@mui/icons-material/Timer';
import MoveUpIcon from '@mui/icons-material/MoveUp';


const QuizData = () => {
  const { id } = useParams();
  const Navigate = useNavigate();
  const [quizzes, setQuizzes] = useState([]);
  const [skeleton,setSkeleton] = useState(false)

  const getQuizzes = async () => {
    setSkeleton(true)
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("brainbucks_token")}`
      );

      var raw = JSON.stringify({
        prerec_couid: parseInt(id),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${base_url}/quiz/prerec_quiz/get/quiz/in/particular/course/by/user`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 1) {
            setQuizzes(result.quizes);
          } else if (result.status === "TOKEN_ERR") {
            localStorage.removeItem("brainbucks_token");
            localStorage.removeItem("username");
            Navigate("/");
            toast.error("Logged in other device! Please login again");
          }
        })
        .catch((error) => console.log("error", error))
        .finally(()=>{
            setSkeleton(false);
        })
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getQuizzes();
  }, []);

  return (
    <>
      <div className="row" style={{ rowGap: "10px" }}>
        
        {quizzes.length > 0 ? (
          quizzes?.slice().reverse().map((data) => (
            <div className="col-12" key={data._id}>
              <Card variant="outlined" className="h-100">
                <CardContent className="quiz-card">
                  {skeleton == true ? (
                    <>
                      <Skeleton variant="text" sx={{ fontSize: "1rem",width:"100%" }} />
                      <Skeleton variant="text" sx={{ fontSize: "1rem",width:"100%" }} />
                    </>
                  ) : (
                    <>
                      <Typography level="title-md">
                        {data.quiz_name}
                      </Typography>
                      <Typography level="body-sm">From {data.prerec_couname}</Typography>
                    </>
                  )}
                </CardContent>
                <CardOverflow
                  variant="soft"
                  sx={{ bgcolor: "background.level1" }}
                >
                {
                    skeleton==true?(
                        <>
                            
                  <Divider inset="context" />
                  <CardContent orientation="horizontal">
                  <Skeleton variant="text" sx={{ fontSize: "1rem",width:"25%" }} />
                    <Divider orientation="vertical" />
                    <Skeleton variant="text" sx={{ fontSize: "1rem",width:"25%" }} />
                    <div className="w-100 d-flex justify-content-end">
                    <Skeleton variant="text" sx={{ fontSize: "1rem",width:"50%" }} />
                    </div>
                  </CardContent>
                        </>
                    ):(<>

                  <Divider inset="context" />
                  <CardContent orientation="horizontal">
                    <Typography
                      level="body-xs"
                      fontWeight="md"
                      textColor="text.secondary"
                      className="d-flex align-items-center gap-1"
                    >
                    <MoveUpIcon sx={{fontSize:"1rem"}} />
                      {data.number_of_question}
                    </Typography>
                    <Divider orientation="vertical" />
                    <Typography
                      level="body-xs"
                      fontWeight="md"
                      textColor="text.secondary"
                      className="d-flex align-items-center gap-1"
                    >
                    <TimerIcon sx={{fontSize:"1rem"}} />
                      {data.time_period}s
                    </Typography>
                    <div className="w-100 d-flex justify-content-end">
                      <Button varient="contained" className="" onClick={()=>Navigate(`/QuizDetails/${data._id}`)}>
                        View Details
                      </Button>
                    </div>
                  </CardContent>
                    </>)
                }
                </CardOverflow>
              </Card>
            </div>
          ))
        ) : (
          <h5 className="text-center d-flex h-100 mt-5 justify-content-center align-items-center">
            No data found !
          </h5>
        )}
      </div>
      <Toaster />
    </>
  );
};

export default QuizData;

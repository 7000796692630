import React from 'react'
import { FaRegEye } from "react-icons/fa";
import { base_url } from '../env';
import { useParams } from 'react-router-dom';


const Notes = ({notes}) => {
  return (
    <>
    
    <div className='p-0 w-full'>


        <ul className='w-full p-0'>
        {notes.length == 0 ? (
                                <>
                                  <h3 style={{ color: "red" }}>
                                    No PDFs to show
                                  </h3>
                                </>
                              ) : (
                                <>
          {notes?.slice().reverse().map((note, index) => (


            <li key={index} style={{listStyle:"none"}}>
              <div className='p-2 mb-2 align-items-center shadow rounded d-flex flex-row justify-content-between ' style={{border:"1px solid black",}}>
              
              <div className='mb-0' >{note.title}</div>
              <div>{<FaRegEye size={"1rem"} onClick={() => {
    const pdfUrl = `${base_url}/auth/azure/stream/${note.pdf_url}/${localStorage.getItem("brainbucks_token")}/${note.prereccou_id}/P/usdhwer34fsdfdsk`;
    window.open(pdfUrl, "_blank");
  }}  />}</div>


              </div>
              </li>

          ))}
          </>)}


        </ul>



      </div>
    
    </>
  )
}

export default Notes
import React from 'react'
import BottomMenu from '../bottom-navigation/BottomMenu'
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import Typography from "@mui/joy/Typography";
import { useNavigate } from 'react-router-dom';
import ProfileData from './ProfileData';


const Profile = () => {
    const Navigate = useNavigate();
  return (
    <>
        <div className="container-fluid w-100" style={{height:"100vh",overflowY:"auto"}} >
        <div className="row h-100">
          <div className="col-12 p-0 h-100">
            <div>
             {/* navbar started  */}
            <div>
              <Box sx={{ flexGrow: 1 }}>
                <AppBar
                  sx={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    paddingTop: "0.7rem",
                    paddingBottom: "0.7rem",
                  }}
                  className="bg-white px-3 d-flex flex-row align-items-center justify-content-between"
                  elevation={0}
                >
                  <TrendingFlatIcon
                    sx={{ color: "black", transform: "rotate(180deg)" }}
                    onClick={() => {
                      Navigate(-1);
                    }}
                  />
                  <Typography level="title-lg">Profile</Typography>
                  <div></div>
                </AppBar>
              </Box>
            </div>
            {/* navbar end  */}
            </div>

            <div className="h-100" >
               <ProfileData/>
            </div>

            <div>
              <BottomMenu />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Profile

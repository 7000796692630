import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { FaRegCirclePlay } from "react-icons/fa6";
import toast, { Toast } from "react-hot-toast";
import { base_url } from "../env";
import { useNavigate } from "react-router-dom";
import { FaRegEye } from "react-icons/fa";
import Skeleton from "@mui/material/Skeleton";
import Modal from 'react-bootstrap/Modal';
import { Typography } from "@mui/material";
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';

const Lessons = ({ lessons }) => {
  const [currentVideoId,setCurrentVideoId] = useState();
  const [show, setShow] = useState(false);
  const [selectedVideoUrl,setSelectedVideoUrl] = useState("");
  const [selectedVideoTitle,setSelectedVideoTitle] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = (id, url, title) => {
    setCurrentVideoId(id);
    setSelectedVideoUrl(url);
    setSelectedVideoTitle(title);
  
    // Show the modal after the state has been updated
    setShow(true);
  };
  
  const [quizSkeleton, setQuizSkeleton] = useState(false);

  const [lecNotesDetails, setLecNotesDetails] = useState([]);
  const [lecQuizDetails, setLecQuizDetails] = useState([]);
  
  const Navigate = useNavigate();

  // for video details of single course
  const getVideoDetails = async(id) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("brainbucks_token")}`
      );
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",

        body: JSON.stringify({
          prereccou_vedio_id: Number(id),
        }),
      };

      fetch(
        `${base_url}/prerec/studymaterails/get/study/materials/of/particular/vedio/by/user`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 1) {
            setLecNotesDetails(result.study_materials);
          } else if (result.status === "VAL_ERR") {
            toast.error(result.Backend_Error);
          } else if (result.status == "CUSTOM_ERR") {
            toast.error(result.Backend_Error);
          } else if (result.status == "TOKEN_ERR") {
            localStorage.removeItem("brainbucks_token");
            localStorage.removeItem("username");
            Navigate("/");
            toast.error("Logged in other device! Please login again");
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };

  // for quiz details of single course
  const getQuizDetails = async(id) => {
    try {
      setQuizSkeleton(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("brainbucks_token")}`
      );
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",

        body: JSON.stringify({
          prerec_videoid: Number(id),
        }),
      };

      fetch(
        `${base_url}/quiz/prerec_quiz/get/quiz/in/particular/vedio/by/user`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 1) {
            setQuizSkeleton(false);
            setLecQuizDetails(result.quizes);
          } else if (result.status === "VAL_ERR") {
            toast.error(result.Backend_Error);
          } else if (result.status == "CUSTOM_ERR") {
            toast.error(result.Backend_Error);
          } else if (result.status == "TOKEN_ERR") {
            localStorage.removeItem("brainbucks_token");
            localStorage.removeItem("username");
            Navigate("/");
            toast.error("Logged in other device! Please login again");
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };

  // console.log('====================================');
  // console.log(lessons[0].id);
  // console.log('====================================');
  

  return (
    <>
      {lessons.length == 0 ? (
        <>
          <h3 style={{ color: "red" }}>No lectures to show</h3>
        </>
      ) : (
        <Accordion defaultActiveKey="0">
          <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            {lessons?.slice().reverse().map((video, key) => (
              <div className="shadow">
                <Accordion.Item
                  eventKey={key}
                  style={{ border: "1px solid black" }}
                >
                  <Accordion.Header>
                    <div
                      className="d-flex flex-row align-items-center justify-content-between flex-grow-1"
                      style={{ width: "full", fontSize: "1rem" }}
                    >
                      <div className="mb-0" >{video.title}</div>
                      <div>
                        {
                          <FaRegCirclePlay
                            size={"1.2rem"}
                            style={{ marginRight: "1rem" }}
                            onClick={()=>handleShow(video.id,video.url,video.title)}
                          />
                        }
                      </div>
                    </div>

                    <Modal centered show={show}   backdrop="static" onHide={handleClose}  style={{zIndex:"9999",height:"100vh !important"}} >
        <Modal.Header closeButton>
          <Modal.Title className="border-0" >
            <Typography level="body-md" noWrap >{selectedVideoTitle}</Typography>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <Video controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']} src={`${base_url}/auth/azure/stream/${selectedVideoUrl}/${localStorage.getItem("brainbucks_token")}/${video.prerec_cou_id}/V/usdhwer34fsdfdsk`} type="video/webm"  >
            </Video>
        </Modal.Body>
      </Modal>

                  </Accordion.Header>

                  <Accordion.Body>
                    <Accordion>
                      {/* quizes */}
                      <Accordion.Item
                        eventKey="0"
                        style={{ border: "1px solid black", marginBottom: 5 }}
                      >
                        <Accordion.Header
                          onClick={() => {
                            getQuizDetails(video.id);
                          }}
                        >
                          Quizes
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="p-0 w-full">
                            <ul className="w-full p-0">
                              {lecQuizDetails.length !== 0 ? (
                                <>
                                  {lecQuizDetails?.slice().reverse().map((data, index) => (
                                    <>
                                      {quizSkeleton === true ? (
                                        <>
                                          <Skeleton
                                            className="rounded"
                                            size="sm"
                                            height="46px"
                                            animation="wave"
                                            variant="rectangular"
                                            elevation={8}
                                            sx={{ marginBottom: 2 }}
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <li
                                            key={index}
                                            style={{ listStyle: "none" }}
                                          >
                                            <div
                                              className="p-2 mb-2 align-items-center rounded d-flex flex-row justify-content-between "
                                              style={{
                                                margin: "auto",
                                                backgroundColor: "#CFE2FF",
                                              }}
                                            >
                                              <div className="mb-0" >{`${index + 1}. ${
                                                data.quiz_name
                                              }`}</div>
                                              <div>
                                                {
                                                  <FaRegEye
                                                    size={"1.2rem"}
                                                    style={{
                                                      marginRight: "1rem",
                                                    }}
                                                    onClick={()=>Navigate(`/QuizDetails/${data._id}`)}
                                                  />
                                                }
                                              </div>
                                            </div>
                                          </li>
                                        </>
                                      )}
                                    </>
                                  ))}
                                </>
                              ) : (
                                <>
                                  <h3 style={{ color: "red" }}>
                                    No PDFs to show
                                  </h3>
                                </>
                              )}
                            </ul>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>

                      {/* notes */}

                      <Accordion.Item
                        eventKey="1"
                        style={{ border: "1px solid black" }}
                      >
                        <Accordion.Header
                          onClick={() => {
                            getVideoDetails(video.id);
                          }}
                        >
                          Notes
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="p-0 w-full">
                            <ul className="w-full p-0">
                              {lecNotesDetails.length == 0 ? (
                                <>
                                  <h3 style={{ color: "red" }}>
                                    No PDFs to show
                                  </h3>
                                </>
                              ) : (
                                <>
                                  {lecNotesDetails?.slice().reverse().map((data, index) => (
                                    <li
                                      key={index}
                                      style={{ listStyle: "none" }}
                                    >
                                      <div
                                        className="p-2 mb-2 rounded d-flex align-items-center flex-row justify-content-between "
                                        style={{
                                          margin: "auto",
                                          backgroundColor: "#CFE2FF",
                                        }}
                                      >
                                        <div className="mb-0" >{`${index + 1}. ${
                                          data.title
                                        }`}</div>
                                        <div>
                                          {
                                            <FaRegEye
                                              size={"1.2rem"}
                                              style={{ marginRight: "1rem" }}
                                              onClick={() => {
    const pdfUrl = `${base_url}/auth/azure/stream/${data.pdf_url}/${localStorage.getItem("brainbucks_token")}/${data.prereccou_id}/P/usdhwer34fsdfdsk`;
    window.open(pdfUrl, "_blank");
  }}
                                            />
                                          }
                                        </div>
                                      </div>
                                    </li>
                                  ))}
                                </>
                              )}
                            </ul>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Accordion.Body>
                </Accordion.Item>
              </div>
            ))}
          </div>
        </Accordion>
      )}
    </>
  );
};

export default Lessons;

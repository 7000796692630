import React, { useEffect, useState } from "react";
import Card from "@mui/joy/Card";
import AspectRatio from "@mui/joy/AspectRatio";
import Carousel from "nuka-carousel";
import Typography from "@mui/joy/Typography";
import SchoolIcon from "@mui/icons-material/School";
import { base_url, token } from "../env";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Skeleton from "@mui/material/Skeleton";

const FreeCourses = () => {
  const [skeleton, setSkeleton] = useState(false);
  const [courses, setCourses] = useState([]);
  const Navigate = useNavigate();

  const getFreeCourses = async () => {
    setSkeleton(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("brainbucks_token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      fetch(
        `${base_url}/prerec/prerecordedcourse/view/free/pre/rec/course/by/user`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          
          if (result.status == 1) {
            setCourses(result.data);
          } else if (result.status === "VAL_ERR") {
            toast.error(result.Backend_Error);
          } else if (result.status == "CUSTOM_ERR") {
            toast.error(result.Backend_Error);
          } else if (result.status == "TOKEN_ERR") {
            localStorage.removeItem("brainbucks_token");
            localStorage.removeItem("username");
            Navigate("/");
            toast.error("Logged in other device! Please login again");
          }
        })
        .catch((error) => console.log("error", error))
        .finally(()=>{setSkeleton(false);})
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getFreeCourses();
  }, []);

  return (
    <>
      <Carousel withoutControls={true} swiping={true} slidesToShow={1.5}>
        {courses?.slice().reverse().map((course, value) => {
          return (
            <>
              {skeleton === true ? (
                <>
                  <Skeleton
                    className="rounded"
                    size="sm"
                    height="130px"
                    animation="wave"
                    variant="rectangular"
                    elevation={8}
                    sx={{ marginBottom: 2 }}
                  />
                </>
              ) : (
                <>
                  <Card
                    onClick={()=>{Navigate(`/ViewCourse/${course.id}`)}}
                    color="neutral"
                    invertedColors
                    orientation="vertical"
                    size="sm"
                    className="h-100"
                    variant="outlined"
                  >
                    <AspectRatio maxHeight="80px">
                      <img
                        className="w-100"
                        src={`${course.banner}`}
                        loading="lazy"
                        alt=""
                      />
                    </AspectRatio>
                    <div className="d-flex align-items-center justify-content-between">
                      <Typography level="title-sm" noWrap>
                        {course.cou_name}
                      </Typography>

                      <Typography level="title-sm">
                        <SchoolIcon style={{ fontSize: "1rem" }} /> {"enrol"}
                      </Typography>
                    </div>
                  </Card>
                </>
              )}
            </>
          );
        })}
      </Carousel>
      <Toaster/>
    </>
  );
};

export default FreeCourses;

import React,{useEffect} from "react";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import Avatar from '@mui/material/Avatar';
import profile from "../assets/aadi.jpg"
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from "react-router-dom";


import toast, { Toaster } from "react-hot-toast";
import {base_url , token , username} from ".././env"



const Navbar = () => {
  const Navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [data,setData] = React.useState({});

  const profileData = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("brainbucks_token")}`
      );

      var raw = "";

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${base_url}/auth/user/auth/view/profile`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 1) {
            setData(result.data);
          } else if (result.status == "TOKEN_ERR") {
            localStorage.removeItem("brainbucks_token");
            localStorage.removeItem("username");
            Navigate("/");
            // toast.error("Logged in other device! Please login again");
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };


  const logOutApi =async()=>{
    try{
      var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("brainbucks_token")}`);
  myHeaders.append("Content-Type", "application/json");
  
  var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  redirect: 'follow',
  };
  
  fetch(`${base_url}/auth/user/auth/logout`, requestOptions)
  .then(response => response.json())
  .then(result => {
    
    if(result.status==1){

      toast.success("Logged Out")
      localStorage.removeItem("brainbucks_token");
      localStorage.removeItem("username");
      setTimeout(()=>{
        Navigate('/');
      } , 1000)


    }
    else if (result.status === 'VAL_ERR') { 
      toast(result.Backend_Error) 
    } 
     else { 
      console.log(result) 
    }
  })
  .catch(error => console.log('error', error));
    }catch(e){
      console.log(e)
    }
  }


  useEffect(() => {
    profileData();
  }, [])
  
  
  return (
    <>
    

      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            paddingTop: "0.7rem",
            paddingBottom: "0.7rem",
          }}
          className="bg-white px-3"
          elevation={0}
        >
          <MenuIcon sx={{ color: "black" }} onClick={() => setOpen(true)} />
        </AppBar>
      </Box>

      {/* drawer code started  */}
      <Drawer open={open} onClose={() => setOpen(false)}>
        {/* Drawer content */}
        <div className=" d-flex align-items-center gap-3 justify-content-start px-2" style={{height:"15%"}} onClick={()=>Navigate('/Profile')} >
            <Avatar
              src={data.profile}
              sx={{ width: 56, height: 56 }}
            />
        <div>
            <p className="mb-0" >{data.name}</p>
            <p className="mb-0" >{data.mobile}</p>
        </div>

        </div>
        <hr/>


        <div className="h-100 px-3 d-flex flex-column gap-3 " >
            {/* <div className="d-flex align-items-center ms-2 gap-2" >
                <InfoIcon/>
                About Us
            </div>
            <div className="d-flex align-items-center ms-2 gap-2" >
                <InfoIcon/>
                About Us
            </div>
            <div className="d-flex align-items-center ms-2 gap-2" >
                <InfoIcon/>
                About Us
            </div>
            <div className="d-flex align-items-center ms-2 gap-2" >
                <InfoIcon/>
                About Us
            </div> */}
            
        </div>

        <div
            onClick={logOutApi}
            className="d-flex justify-content-center align-items-center btn  gap-3 py-3 mx-3 mb-2"
            style={{
              background: "#FFEFEF",
              borderRadius: "10px",
              cursor: "pointer",
              color: "#F93333",
              fontWeight:"500",
              fontSize:"0.9rem"
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
  <path d="M0 8L4.64286 12V9H13V7H4.64286V4L0 8Z" fill="#F93333"/>
  <path d="M9.79863 3.05398e-05C8.72121 -0.00288353 7.6539 0.202773 6.65854 0.605085C5.66318 1.0074 4.75955 1.59837 4 2.34377L5.28838 3.60051C6.49294 2.42553 8.09476 1.77761 9.79863 1.77761C11.5025 1.77761 13.1043 2.42553 14.3089 3.60051C15.5134 4.77549 16.1777 6.33798 16.1777 8.00002C16.1777 9.66205 15.5134 11.2245 14.3089 12.3995C13.1043 13.5745 11.5025 14.2224 9.79863 14.2224C8.09476 14.2224 6.49294 13.5745 5.28838 12.3995L4 13.6563C5.54806 15.1672 7.60729 16 9.79863 16C11.99 16 14.0492 15.1672 15.5973 13.6563C17.1462 12.1462 18 10.1376 18 8.00002C18 5.86248 17.1462 3.85382 15.5973 2.34377C14.8377 1.59837 13.9341 1.0074 12.9387 0.605085C11.9434 0.202773 10.8761 -0.00288353 9.79863 3.05398e-05Z" fill="#F93333"/>
</svg>
            <span >Log Out</span>
          </div>



      </Drawer>
      {/* drawer code end  */}

      <Toaster position="top-center" />
    </>
  );
};

export default Navbar;

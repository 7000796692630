import React from 'react'
import QuizNavbar from './QuizNavbar'
import QuizData from './QuizData'

const Quiz = () => {
  return (
    <>
              <div
        className="container-fluid w-100"
        style={{ height: "100vh", overflowY: "auto" }}
      >
        <div className="row h-100">
          <div className="col-12 p-0">
            <div  style={{borderBottom:"1px solid red !important"}}>
              <QuizNavbar />
            </div>

            <div className="py-5 mb-3 px-3 h-100" >
              <QuizData />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Quiz

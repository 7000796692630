import React from 'react'
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/joy/Typography';
const QuizNavbar = () => {
    const Navigate = useNavigate();
  return (
    <>
         <Box sx={{ flexGrow: 1 }} >
        <AppBar
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            paddingTop: "0.7rem",
            paddingBottom: "0.7rem",
          }}
          className="bg-white px-3 d-flex flex-row align-items-center justify-content-between"
          elevation={0}
        >
          <TrendingFlatIcon sx={{ color: "black",transform:"rotate(180deg)" }} onClick={()=>{Navigate(-1)}} />
          <Typography level="title-lg">Quizzes</Typography>
          <div></div>
        </AppBar>
      </Box>
    </>
  )
}

export default QuizNavbar
